body {
  max-width: 895px;
  margin: 5px auto;
  font-family: Verdana, Geneva, sans-serif;
}

.form-container .header {
  background-color: #ff6600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 10px;
}

.form-container .header a {
  color: black;
}

.form-container .header h1 {
  font-size: 13.33px;
  margin: 0;
}

.form-container .header span {
  font-size: 13.33px;
}

.form-container .form-content {
  background-color: #f6f6ef;
  width: 100%;
  padding: 10px 0;
}

.form-container .label {
  color: #828282;
  font-size: 13.33px;
  font-weight: normal;
  width: 35px;
  padding: 5px 0 5px 5px;
}

.form-container .input-container {
  display: flex;
  align-items: center;
}

.form-container .input-container input {
  height: 17.5px;
  max-width: 412px;
  padding: 1px 2px;
  width: 100%;
  font-family: monospace;
}

/* --------------------- */

.post-list-container {
  margin-top: 30px;
}

.post-list-container img {
  border: 1px solid white;
  margin-right: 5px;
}

.post-list-container .header {
  background-color: #ff6600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 10px;
}

.post-list-container .header a {
  margin: 0 3px;
  font-size: 13.33px;
  text-decoration: none;
  color: black;
}

.post-list-container .header .flex-center a:first-child {
  margin-left: 0 !important;
  font-weight: bold;
}
.post-list-container .header .flex-center a:last-child {
  margin-right: 0 !important;
}

.flex-center {
  display: flex;
  align-items: center;
}

/* -------------- */

.post-container {
  display: flex;
  align-items: flex-start;
  background-color: #f6f6ef;
  padding: 5px 10px;
  color: #828282;
}

.post-container div {
  font-weight: 13.33px !important;
}

.arrow-container {
  display: flex;
  align-items: center;
  width: 40px;
  text-align: right;
  justify-content: flex-end;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 10px solid #828282;
  margin-right: 5px;
  margin-left: 5px;
}

.post-container a {
  text-decoration: none;
}

.post-container .title {
  color: black;
  font-size: 13.33px;
  font-family: Verdana, Geneva, sans-serif;
}

.post-container .subtitle {
  color: #828282;
  font-size: 9.33px;
  font-family: Verdana, Geneva, sans-serif;
}

.link-title {
  font-size: 10.66px;
  color: #828282;
}
